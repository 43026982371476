var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Mã phiếu: " + _vm.dataInput.code,
            visible: _vm.dialogVisible,
            width: "950px",
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            top: "10vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataInput",
              attrs: {
                "label-width": "100px",
                model: _vm.dataInput,
                "label-position": "left",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "350px" },
                  attrs: { label: "Số tiền chi", prop: "money" },
                },
                [
                  _c("el-currency-input", {
                    model: {
                      value: _vm.dataInput.money,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataInput, "money", $$v)
                      },
                      expression: "dataInput.money",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: {
                    width: "350px",
                    float: "right",
                    "margin-right": "20px",
                    "margin-top": "-62px",
                  },
                  attrs: { label: "Ngày thu", prop: "date" },
                },
                [
                  _c(
                    "el-date-picker",
                    {
                      staticClass: "button-left-class1",
                      attrs: {
                        type: "date",
                        format: "dd-MM-yyyy",
                        "value-format": "yyyy-MM-dd",
                        disabled: _vm.checkDisable,
                        placeholder: "Chọn ngày",
                        "picker-options": _vm.pickerOptions,
                      },
                      model: {
                        value: _vm.dataInput.date,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataInput, "date", $$v)
                        },
                        expression: "dataInput.date",
                      },
                    },
                    [_vm._v("\n          >")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "350px" },
                  attrs: { label: "Người chi" },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.dataInput.nameInternal,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataInput, "nameInternal", $$v)
                      },
                      expression: "dataInput.nameInternal",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: {
                    width: "320px",
                    float: "right",
                    "margin-right": "50px",
                    "margin-top": "-62px",
                  },
                  attrs: { label: "Người nhận" },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.dataInput.nameOther,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataInput, "nameOther", $$v)
                      },
                      expression: "dataInput.nameOther",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "860px" },
                  attrs: { label: "Nội dung", prop: "content" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 3,
                      placeholder: "Nhập nội dung",
                    },
                    model: {
                      value: _vm.dataInput.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataInput, "content", $$v)
                      },
                      expression: "dataInput.content",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: { label: "Thanh toán", prop: "payment" },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.dataInput.payment,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataInput, "payment", $$v)
                        },
                        expression: "dataInput.payment",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { disabled: "", label: true } }, [
                        _vm._v("Đã thanh toán"),
                      ]),
                      _c(
                        "el-radio",
                        { attrs: { disabled: "", label: false } },
                        [_vm._v("Chưa thanh toán")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-close" }),
                  _c("span", [_vm._v(_vm._s(_vm.$t("button.close")))]),
                ]
              ),
              _c(
                "el-button",
                {
                  staticClass: "button-over",
                  attrs: { type: "success" },
                  on: {
                    click: function ($event) {
                      return _vm.generateReport(_vm.dataInput.id)
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-printer" }),
                  _vm._v("\n        In hóa đơn\n      "),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("PeopleTypeCreate", {
        attrs: { dialogVisible: _vm.showCreateDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseCreateMethod()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }